// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-edit-js": () => import("./../../../src/pages/account/edit.js" /* webpackChunkName: "component---src-pages-account-edit-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-summary-js": () => import("./../../../src/pages/account/summary.js" /* webpackChunkName: "component---src-pages-account-summary-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-loginfc-js": () => import("./../../../src/pages/loginfc.js" /* webpackChunkName: "component---src-pages-loginfc-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-news-detail-js": () => import("./../../../src/pages/news/detail.js" /* webpackChunkName: "component---src-pages-news-detail-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-tags-js": () => import("./../../../src/pages/news/tags.js" /* webpackChunkName: "component---src-pages-news-tags-js" */),
  "component---src-pages-product-detail-js": () => import("./../../../src/pages/product/detail.js" /* webpackChunkName: "component---src-pages-product-detail-js" */),
  "component---src-pages-product-index-js": () => import("./../../../src/pages/product/index.js" /* webpackChunkName: "component---src-pages-product-index-js" */)
}

