import React from 'react'

const AuthContext = React.createContext({
  session: "",
  memberType: "",
  personalCode: "",
  updateSession: () => {}
})

export default AuthContext
