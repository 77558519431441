import React, {useState, useEffect} from 'react'
import Cookies from "js-cookie"
import _ from "lodash"
import AuthContext from "./AuthContext"

export const getCookieSess = () => Cookies.get(process.env.STORE_SESSION_NAME)
export const getPersonalCode = () => _.get(JSON.parse(window.localStorage.getItem("personal")), "personal_c")
export const getMemberType = () => _.get(JSON.parse(window.localStorage.getItem("personal")), "member_type")

export default ({children}) => {
  const [session, setSession] = useState(null)
  const [memberType, setMemberType] = useState(null)
  const [personalCode, setPersonalCode] = useState(null)
  const updateSession = () => setSession(getCookieSess())

  useEffect(() => {
    setSession(getCookieSess())
    setMemberType(getMemberType())
    setPersonalCode(getPersonalCode())
  }, [])

  return (
    <AuthContext.Provider
      value={{
        session,
        memberType,
        personalCode,
        updateSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
