import React, {useState, useEffect} from 'react'
import _ from "lodash"
import FcContext from "./FcContext"

export const getFcSession = (storageKey) => _.get(JSON.parse(window.localStorage.getItem(storageKey)), "sess")
export const getFcPersonalCode = (storageKey) => _.get(JSON.parse(window.localStorage.getItem(storageKey)), "personal_c")
export const getFcMemberType = (storageKey) => _.get(JSON.parse(window.localStorage.getItem(storageKey)), "member_type")
export const deleteFcSession = (storageKey) => window.localStorage.removeItem(storageKey)

export default ({children}) => {
  const [fcSession, setSession] = useState(null)
  const [fcMemberType, setMemberType] = useState(null)
  const [fcPersonalCode, setPersonalCode] = useState(null)
  const updateSession = () => setSession(getFcSession())

  useEffect(() => {
    setSession(getFcSession())
    setMemberType(getFcMemberType())
    setPersonalCode(getFcPersonalCode())
  }, [])

  return (
    <FcContext.Provider
      value={{
        fcSession,
        fcMemberType,
        fcPersonalCode,
        updateSession,
      }}
    >
      {children}
    </FcContext.Provider>
  )
}
