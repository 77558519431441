import React, {useState, useEffect} from 'react'
import {ModdApi} from "../../libraries/modd"
import CartContext from "./CartContext"
import _ from "lodash"
import {AppSync} from "../../libraries/appsync"

export default ({children}) => {
  const [cartInfo, setCartInfo] = useState(0)
  const [cartLoading, setCartLoading] = useState(true)

  const updateCartInfo = (cartInfo) => {
    setCartInfo(cartInfo || {})
    setCartLoading(true)
    const promises = []
    if (cartInfo !== undefined && Array.isArray(cartInfo.ItemElements)) {
      Object.keys(cartInfo.ItemElements).forEach(key => {
        promises.push(AppSync.post("/", {
          query: `
          query getProduct {
            getProduct(Hin:"${cartInfo.ItemElements[key].ProCataNo}") {
              Hin
              ecSellingStatus
            }
          }
        `
        }))
      })  
      Promise.all(promises).then(results => {
        Object.keys(cartInfo.ItemElements).forEach(key => {
          const productData = _.get(results[key], "data.data.getProduct")
          cartInfo.ItemElements[key].sellingStatus = productData.ecSellingStatus
        })
        setCartInfo(cartInfo)
        setCartLoading(false)
      })
    } else {
      setCartInfo(cartInfo || {})
      setCartLoading(false)
    }
  }

  useEffect(() => {
    ModdApi.get(`/GetItems?cartId=${process.env.MODD_CART_ID}`)
    .then(res => {
      updateCartInfo(res.data || {})
    })
    .catch(err => {
      updateCartInfo({})
    })
  }, [])

  return (
    <CartContext.Provider value={{
      cartInfo,
      cartLoading,
      updateCartInfo,
    }}>
      {children}
    </CartContext.Provider>
  )
}